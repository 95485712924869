import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import { ReactComponent as EmailLogoAlternate } from 'assets/logos/svg/email-alternate.svg';
import useMagicLinkHandler from 'hooks/useMagicLinkHandler';
import useFirebaseError from 'hooks/useFirebaseError';
import useLoginToken from 'hooks/useLoginToken';
import Button from 'components/atomic/Button';
import useFirebase from 'hooks/useFirebase';

interface UnitTagLoginFormProps {
  isDrawer?: boolean;
  handleLogin?: (provider: string) => void;
}

const UnitTagLoginForm: React.FC<UnitTagLoginFormProps> = ({
  handleLogin: handleLoginProp = (provider: string) => {},
  isDrawer,
}) => {
  const { getAuth, signInWithCustomToken } = useFirebase();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const { t } = useTranslation('translation', { keyPrefix: 'signIn' });
  const { productDetails, setRegisteringProduct } = useGlobal();
  const { openDrawer, closeSuccessDrawer } = useSuccessDrawerContext();
  const getErrorMessage = useFirebaseError();
  const auth = getAuth();
  const email = productDetails!.unitTagDetails!.email;
  const {
    handleMagicLink,
    loading: magicLinkLoading,
    error: magicLinkError,
    success,
  } = useMagicLinkHandler(email);

  const onSuccess = useCallback(
    (response) => {
      if (response.existingUser) {
        setLoading(false);
        return handleMagicLink();
      } else if (response.token) {
        if (isDrawer) {
          openDrawer();
        }

        signInWithCustomToken(auth, response.token)
          .then(() => {
            if (!isDrawer) {
            } else {
              handleLoginProp('email');
              setLoading(false);
            }
          })
          .catch((error) => {
            closeSuccessDrawer();
            showToast({ message: getErrorMessage(error.code), type: 'error' });
          });
      }
    },
    [
      auth,
      isDrawer,
      openDrawer,
      handleMagicLink,
      handleLoginProp,
      getErrorMessage,
      closeSuccessDrawer,
      setRegisteringProduct,
    ]
  );

  const [getToken] = useLoginToken(onSuccess);

  const handleLogin = useCallback(async () => {
    setLoading(true);
    if (error !== '') setError('');
    try {
      await getToken({
        email,
      });
    } catch (e) {
      // catch error when user exist in firebase but not in our db
      showToast({
        message: t('toastMessages.cantVerifyLoginDetails'),
        type: 'error',
      });
    } finally {
      setEmailSent(true);
      setLoading(false);
    }
  }, [error, getToken, t, email]);

  const getRegisterButtonText = () => {
    if (emailSent) return t('checkYourEmail');
    return t('unitTagSignInLink');
  };

  return (
    <div className='w-full flex flex-col items-center justify-start my-1.5'>
      <Button
        transition
        variant='dark'
        loading={loading || magicLinkLoading}
        title={getRegisterButtonText()}
        disabled={emailSent}
        onClick={handleLogin}
        icon={
          <EmailLogoAlternate
            fill={emailSent ? '' : '#FFFFFF'}
            className='absolute left-3'
          />
        }
      />
      {magicLinkError && (
        <span className='mt-3 mb-1 px-4 text-[0.7rem] text-warning'>
          {magicLinkError}
        </span>
      )}
      {success && (
        <span className='mt-3 mb-1 px-4 text-[0.7rem] text-success'>
          {success}
        </span>
      )}
    </div>
  );
};

export default UnitTagLoginForm;
