import React, { useState, useCallback, useEffect } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { ReactComponent as Close } from 'assets/icons/svg/close.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { initialScreenToShow } from 'utils/screenToShow';
import { useGlobal } from 'context/global/GlobalContext';
import { useTranslation } from 'react-i18next';
import { Position } from 'types/Misc';
import ScreenType from 'types/ScreenType';
import usePrevious from 'hooks/usePrevious';
import Dragbar from 'components/atomic/Dragbar';
import DragZone from 'components/atomic/DragZone';

type AutoDeployProps = {
  isOpen: boolean;
  closeDrawer: () => void;
  children: React.ReactNode;
  isProductRegistered: boolean;
};

const AutoDeploy: React.FC<AutoDeployProps> = ({
  isOpen,
  children,
  closeDrawer,
  isProductRegistered,
}) => {
  const [deltaPosition, setDeltaPosition] = useState<number>(0);
  const [isControlled, setIsControlled] = useState<boolean>(true);
  const [drawerTitle, setDrawerTitle] = useState<string>('');
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: window.innerHeight,
  });
  const {
    token,
    screen,
    setScreen,
    formTitle,
    desktopView,
    registerProduct,
    registrationScreen,
  } = useGlobal();
  const { menuStyle } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.autoDeploy',
  });

  const previousOpenState = usePrevious(isOpen);

  const bottomHeight = window.innerHeight;
  const topHeight = 0;

  useEffect(() => {
    if (!previousOpenState && isOpen) {
      if (
        registrationScreen === ScreenType.COMPLETE_PROFILE &&
        isProductRegistered
      ) {
        setScreen(ScreenType.COMPLETE_PROFILE);
      } else {
        const screenToRender = initialScreenToShow(
          {
            registrationRequired: true,
          },
          token,
          null,
          {
            registeredToCurrentUser: isProductRegistered,
          },
          registrationScreen,
          undefined,
          registerProduct
        );

        if (screenToRender) {
          setScreen(screenToRender);
        }

        setTimeout(() => setPosition({ ...position, y: topHeight }), 500);
      }
    } else {
      setTimeout(() => setPosition({ ...position, y: 0 }), 500);
    }
  }, [
    isOpen,
    previousOpenState,
    token,
    registrationScreen,
    desktopView,
    isProductRegistered,
  ]);

  useEffect(() => {
    if (screen === ScreenType.PURCHASE_DETAILS)
      setDrawerTitle(t('titles.purchaseScreen'));
    else if (screen === ScreenType.COMPLETE_PROFILE)
      setDrawerTitle(desktopView ? '' : t('titles.completeProfileScreen'));
    else if (screen === ScreenType.FORM) setDrawerTitle(formTitle);
    else setDrawerTitle('');
  }, [t, screen, formTitle]);

  const handleStart = useCallback(() => setIsControlled(false), []);

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ ...position, y: data.y });
    setDeltaPosition(data.deltaY);
  };

  const handleStop = useCallback(() => {
    setIsControlled(true);
    if (deltaPosition > 0) {
      setPosition({ ...position, y: bottomHeight });
      closeDrawer();
    } else {
      if (position.y === bottomHeight) {
        setPosition({ ...position, y: bottomHeight });
        closeDrawer();
      } else setPosition({ ...position, y: topHeight });
    }
  }, [topHeight, bottomHeight, deltaPosition, position, setPosition]);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-tl-[26px] rounded-tr-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-tl-[10px] rounded-tr-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-tl-[26px] rounded-tr-[26px]';
    }
  };

  if (!isOpen) return null;

  return (
    <Draggable
      axis='y'
      bounds={{ top: topHeight, bottom: window.innerHeight }}
      onStart={handleStart}
      position={position}
      onDrag={handleDrag}
      onStop={handleStop}
      cancel='a, button, .not-draggable'
    >
      <div
        style={
          desktopView ? { boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)' } : {}
        }
        className={`z-40 absolute left-0 bg-white overflow-auto overflow-x-hidden 
          ${
            desktopView
              ? 'lg:h-[calc(100%_-_14px)] lg:left-[250px] lg:w-[calc(100%-250px)]'
              : 'w-full h-[88%] shadow-drawer'
          } 
          ${
            isOpen
              ? `${desktopView ? 'lg:top-3.5 lg:pt-12' : 'top-[12%]'}`
              : 'top-[100%]'
          } 
          ${isControlled ? 'duration-500' : 'duration-0'} 
          ${getCornerStyle()}
        `}
      >
        <div className='z-[60] fixed top-0 left-0 w-full h-16 min-h-16 rounded-[26px] bg-white'>
          <div className='absolute top-2 left-0 right-0 mx-auto'>
            <Dragbar />
          </div>
          <DragZone />
          <button
            aria-label={t('ariaCloseDrawer')}
            className='z-[100] absolute right-8 top-4 w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full cursor-pointer bg-drawerClose'
            onClick={() => {
              setPosition({ ...position, y: bottomHeight });
              setTimeout(() => closeDrawer(), 300);
            }}
          >
            <Close aria-hidden fill='#414149' />
          </button>
          <div className='z-10 relative w-full h-16 flex items-center rounded-[26px]'>
            <h1
              tabIndex={0}
              className='w-[calc(100%-6rem)] mt-1 mr-2 ml-6 text-base font-semibold text-left leading-5'
            >
              {drawerTitle}
            </h1>
          </div>
        </div>
        <div className='not-draggable relative w-full h-full flex flex-col items-center justify-start pt-4 px-5 z-50 overflow-x-hidden overflow-y-scroll'>
          {children}
        </div>
      </div>
    </Draggable>
  );
};

export default AutoDeploy;
