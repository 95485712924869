import { useAPICacheContext } from 'context/APICacheContext/APICacheContext';
import { useGlobal } from 'context/global/GlobalContext';
import { useCallback, useState } from 'react';
import { useAPI } from 'utils/api';
import { useRecaptchaV3Token } from './useRecaptchaV3';

const useRegisterProduct = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    slug,
    token,
    receiptData,
    getPersonalDetails,
    setReceiptData,
    setRegistrationId,
    newRegistrationId,
    setNewRegistrationId,
  } = useGlobal();

  const { invalidateCache } = useAPICacheContext();
  const { token: recaptchaToken } = useRecaptchaV3Token();
  const [registerProduct] = useAPI(
    {
      method: 'POST',
      endpoint: `products/register/${slug}`,
    },
    token,
    true,
    false,
    recaptchaToken || ''
  );

  // Save receipt data globally and check here
  const registerProductAndFetch = useCallback(
    async (warrantyId: string | null = null, emailConsent: boolean = false) => {
      setLoading(true);
      let registerationResponse = null;

      const consentsByUser = [];

      if (receiptData?.smsConsent) {
        consentsByUser.push(receiptData.smsConsent);
        delete (receiptData as any).smsConsent;
      }

      try {
        registerationResponse = await registerProduct({
          warrantyId,
          emailConsent,
          registrationId: newRegistrationId,
          receiptData: receiptData || null,
          consentsByUser,
        });
        await getPersonalDetails();
        invalidateCache();
        setRegistrationId(registerationResponse.registrationId);
        return;
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
        setReceiptData(null);
        setNewRegistrationId(null);
      }
    },
    [registerProduct, receiptData, newRegistrationId]
  );

  return { registerProductAndFetch, loading };
};

export default useRegisterProduct;
