import React from 'react';
import { User } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { getRegisterText } from 'utils/getRegisterText';
import { useGlobal } from 'context/global/GlobalContext';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import LoginForm from 'components/forms/LoginForm';

interface RegistrationLoginProps {
  onRegistration: (provider: string, user?: User) => Promise<void>;
}

const RegistrationLogin: React.FC<RegistrationLoginProps> = ({
  onRegistration,
}) => {
  const { productDetails, productModule, setProductModule } = useGlobal();
  const { openDrawer, closeSuccessDrawer } = useSuccessDrawerContext();
  const registrationType = productDetails?.registration?.registrationType;
  const customCallToAction = productDetails?.registration?.customCallToAction;
  const emailConsentCheck = productDetails?.registration.consentCheck;
  const emailConsentDetails = productDetails?.registration.consentDetail;
  const privacyConsentCheck = productDetails?.registration.privacyConsent;
  const privacyConsentDetails =
    productDetails?.registration.privacyConsentDetail;

  const { t } = useTranslation('translation', { keyPrefix: 'signIn' });
  const { t: socialLoginTranslation } = useTranslation('translation', {
    keyPrefix: 'socialLogin',
  });

  const register = (provider: string, user?: User) => {
    // we need to wait for the drawer to open before we call the onRegistration callback
    setTimeout(() => {
      onRegistration(provider, user);
      if (productModule) setProductModule(productModule);
    }, 500);
  };

  const handleSocialLoading = (loading: boolean) => {
    if (loading) {
      openDrawer();
      return;
    }
    closeSuccessDrawer();
  };

  return (
    <LoginForm
      handleLogin={register}
      emailConsentCheck={emailConsentCheck || false}
      emailConsentDetails={emailConsentDetails}
      privacyConsentCheck={privacyConsentCheck || false}
      privacyConsentDetails={privacyConsentDetails}
      handleSocialLoading={handleSocialLoading}
      hideSignupOptions={productDetails?.registration?.hideSignupOptions}
      magicLinkRedirect={MAGIC_ACTION.OPEN_MODULE}
      buttonText={{
        email: `${getRegisterText(registrationType, customCallToAction)} ${t(
          'withEmail'
        )}`,
        google: `${getRegisterText(
          registrationType,
          customCallToAction
        )} ${socialLoginTranslation('withGoogleText')}`,
      }}
    />
  );
};

export default RegistrationLogin;
