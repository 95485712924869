import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shadeChanger } from 'utils/shadeChanger';
import { getImageMeta } from 'utils/productUtils';
import { ProductSearchResultsType } from 'types/DigitalHubTypes';
import ProgressiveImage from 'react-progressive-image';
import useRedirectLink from 'hooks/useRedirectLink';

type DropdownProps = {
  results: ProductSearchResultsType[];
  invertBorders: boolean;
  borderRadius: string;
  background: string;
  color: string;
  style: string;
};

const DropDownExperienceItem = ({
  result,
  background,
  redirectToExternalLink,
  mediaClass,
  color,
}: any) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);

  const mediaLink = result?.image || '';

  let { mediaToUse, mediaType } = getImageMeta(mediaLink, renderOptimizedMedia);

  return (
    <li
      key={result.id}
      style={{ background: background }}
      onClick={() =>
        redirectToExternalLink(
          `${window.location.protocol}//${
            window.location.host
          }/c/${result.slug.toUpperCase()}`,
          '_self'
        )
      }
      className='w-full h-[4.125rem] min-h-[4.125rem] flex items-center px-6 cursor-pointer hover:brightness-110'
    >
      {mediaToUse ? (
        mediaType.includes('mp4') || mediaType.includes('webm') ? (
          <video
            loop
            muted
            autoPlay
            controls={false}
            playsInline={true}
            className={mediaClass}
            onError={(e) => {
              e.currentTarget.src = mediaLink; // replace with your fallback video URL
            }}
          >
            <source src={mediaToUse} type={mediaType} />
          </video>
        ) : (
          <ProgressiveImage
            src={mediaToUse}
            placeholder={result?.name}
            onError={() => {
              setRenderOptimizedMedia(false);
              console.error('Optimized Image Error');
            }}
          >
            {(src: string, loading: boolean) => {
              return loading ? (
                <div className={mediaClass} />
              ) : (
                <img src={src} alt={result?.name} className={mediaClass} />
              );
            }}
          </ProgressiveImage>
        )
      ) : null}
      <p style={{ color: color }}>{result.name}</p>
    </li>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  color,
  style,
  results,
  background,
  borderRadius,
  invertBorders,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'digitalHub' });
  const redirectToExternalLink = useRedirectLink();

  const getCornerRadius = () => {
    if (style === 'largeCorner') return 'rounded-[50px]';
    else if (style === 'smallCorner') return 'rounded-[10px]';
    else if (style === 'noCorner') return 'rounded-none';
    else return 'rounded-[50px]';
  };

  const borderRadiusStyle = (invertBorders: boolean) => {
    if (invertBorders) {
      return {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      };
    } else
      return {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      };
  };

  const mediaClass = `relative w-searchBarMedia h-searchBarMedia mr-3 object-cover ${getCornerRadius()}`;

  return (
    <ul
      className={`relative w-full flex flex-col h-max max-h-[216px] py-1.5 overflow-auto duration-300 
      ${invertBorders ? 'border-b-0' : 'border-t-0'}`}
      style={{
        ...{
          border: background
            ? `1px solid ${shadeChanger(background, 30)}`
            : '1px solid #e7eaeb',
          background: background || '#FFFFFF',
        },
        ...borderRadiusStyle(invertBorders),
      }}
    >
      {results.length > 0 ? (
        results?.map((result, i) => (
          <DropDownExperienceItem
            result={result}
            background={background}
            redirectToExternalLink={redirectToExternalLink}
            mediaClass={mediaClass}
            color={color}
            key={i}
          />
        ))
      ) : (
        <li
          key='no-result'
          style={{ background: background }}
          className='w-full h-[4.125rem] min-h-[4.125rem] flex items-center px-6 cursor-pointer hover:brightness-110'
        >
          <p
            style={{ color: color }}
            className='w-full text-center font-semibold text-sm opacity-60'
          >
            {t('dropdownNoResults')}
          </p>
        </li>
      )}
    </ul>
  );
};

export default Dropdown;
