import React, { useEffect } from 'react';
import { getLocalStorage } from 'utils/storage';
import { useGlobal } from 'context/global/GlobalContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';

const PreviewHandler: React.FC = () => {
  const {
    toggleContentGateDisplay,
    setOpenAutoDeployModal,
    toggleAgegateDisplay,
    setpersonalDetails,
    setGlobalLoading,
    setReceiptData,
    setAllowClose,
    setScreen,
  } = useGlobal();
  const {
    setMeta,
    openDrawer,
    showSuccess,
    closeSuccess,
    setCloseOnComplete,
    closeSuccessDrawer,
  } = useSuccessDrawerContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const listener = (event: MessageEvent<any>) => {
      const type = event?.data?.type;
      const data = event?.data?.data;
      // if we get a message from the preview iframe
      switch (type) {
        case 'toggleSuccessDrawer':
          if (data.show) {
            setCloseOnComplete(false);
            setMeta(data.meta);
            showSuccess();
            openDrawer();
          } else {
            setCloseOnComplete(true);
            closeSuccessDrawer();
            closeSuccess();
          }
          break;
        case 'toggleAgeGate':
          if (data) {
            getLocalStorage().removeItem('ageGateChecked');
            toggleAgegateDisplay(true);
          } else {
            toggleAgegateDisplay(false);
          }
          break;
        case 'toggleContentGate':
          if (data) {
            toggleContentGateDisplay(true);
          } else {
            toggleContentGateDisplay(false);
          }
          break;
        case 'toggleAutoDeploy':
          setOpenAutoDeployModal(data);
          break;
        case 'auth':
          setpersonalDetails(event?.data?.data);
          break;
        case 'clearPurchaseDetails':
          setReceiptData(null);
          break;
        case 'scrollToTop':
          window.scrollTo(0, 0);
          break;
        case 'scrollToBottom':
          window.scrollTo(0, document.body.scrollHeight);
          break;
        case 'setGlobalLoading':
          setGlobalLoading(data);
          break;
        case 'setScreen':
          debugger;
          if (!location.pathname.includes(`/c/1234`)) {
            history.push(`/c/1234`);
          }
          setScreen(data);
          break;
        case 'allowClose':
          setAllowClose(data);
          break;
        default:
          return;
      }
    };

    window.addEventListener('message', listener, false);

    return () => window.removeEventListener('message', listener);
  }, [location]);

  return null;
};

export default PreviewHandler;
