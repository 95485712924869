import React, { useCallback, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useGlobal } from 'context/global/GlobalContext';
import { theme } from 'styles/theme';
import './style.css';
import {
  withLastLocation,
  WithLastLocationProps,
} from 'react-router-last-location';
import {
  CornerStyle,
  useThemeContext,
} from 'context/ThemeContext/ThemeContext';
import Login from 'pages/Login/Login';
import FourZeroFour from 'pages/404/404';
import Profile from 'pages/Profile/Profile';
import Landing from 'pages/Landing/Landing';
import MagicLink from 'pages/MagicLink/MagicLink';
import GameRules from 'pages/GameRules/GameRules';
import Collection from 'pages/Collection/Collection';
import handleTranslation from 'utils/handleTranslation';
import ProtectedRoute from 'components/routing/ProtectedRoute';
import ProductDetails from 'pages/ProductDetails/ProductDetails';
import TermsAndPrivacy from 'pages/TermsAndPrivacy/TermsAndPrivacy';

interface RoutesType {
  [key: string]: any;
}

export const RoutesHashMap: RoutesType = {
  Login: {
    path: '/app/login',
    component: <Login />,
    exact: true,
  },
  MagicLink: {
    path: '/app/magic-link',
    component: <MagicLink />,
    exact: true,
  },
  FourZeroFour: {
    path: '/app/404',
    component: <FourZeroFour />,
    exact: true,
  },
  Collection: {
    path: '/app/collection',
    component: <Collection />,
    protected: true,
    exact: true,
  },
  Profile: {
    path: '/app/profile',
    component: <Profile />,
    protected: true,
    exact: true,
  },
  Terms: {
    path: (id: string = `:id`) => `/p/${id}`,
    component: <TermsAndPrivacy />,
    exact: true,
  },
  GameRules: {
    path: (id: string = `:id`) => `/s/${id}`,
    component: <GameRules />,
    exact: true,
  },
  ProductDetails: {
    path: (id: string = ':id') => `/c/${id}`,
    component: <ProductDetails />,
    exact: false,
  },
  Landing: {
    path: '/app/l/:brandname',
    component: <Landing />,
    exact: true,
  },
};

const getTransition = (path: string, lastLocation: string) => {
  switch (path) {
    case '/app/login':
      return 'inverseslide';
    case '/app/collection':
      return lastLocation === null || lastLocation === '/app/login'
        ? 'slide'
        : 'inverseslide';
    case '/app/profile':
      return lastLocation === '/app/collection' ? 'slide' : 'inverseslide';
    default:
      return 'slide';
  }
};

const Routes: React.FC<WithLastLocationProps> = ({ lastLocation }) => {
  const {
    setButtonStyle,
    setMenuStyle,
    setInputStyle,
    setSecondaryBrandColor,
  } = useThemeContext();
  const location = useLocation();
  const { productDetails } = useGlobal();

  const getCurrentTransition = useCallback(() => {
    return getTransition(
      location.pathname,
      lastLocation ? lastLocation?.pathname : ''
    );
  }, [location.pathname, lastLocation]);

  const getLocationKey = () => {
    // Don't rerender on a nested route.
    if (location.pathname.includes('/c/')) return `products`;
    return location.key;
  };

  useEffect(() => {
    // TODO: Replace fallback with experience default language
    if (
      !productDetails ||
      !productDetails.basicTranslationEnabled ||
      localStorage.getItem('default-language') == 'en' ||
      (navigator.language.split('-')[0] == 'en' &&
        !localStorage.getItem('default-language'))
    ) {
      return;
    }
    console.log('Translation enabled..');
    handleTranslation({ productDetails });
  }, [productDetails]);

  // Reset theme
  useEffect(() => {
    if (!location.pathname.includes('/c/')) {
      setButtonStyle(CornerStyle.FULL_ROUND);
      setInputStyle(CornerStyle.FULL_ROUND);
      setMenuStyle(CornerStyle.FULL_ROUND);
      setSecondaryBrandColor(theme.secondary);
    }
  }, [
    location.pathname,
    setButtonStyle,
    setSecondaryBrandColor,
    setMenuStyle,
    setInputStyle,
  ]);

  return (
    <TransitionGroup>
      <CSSTransition
        timeout={200}
        key={getLocationKey()}
        classNames={getCurrentTransition()}
      >
        <Switch location={location}>
          {Object.keys(RoutesHashMap).map((routeKey) => {
            const routeObject = RoutesHashMap[routeKey];
            const path =
              typeof RoutesHashMap[routeKey].path === 'function'
                ? RoutesHashMap[routeKey].path()
                : RoutesHashMap[routeKey].path;
            if (routeObject.protected) {
              return (
                <ProtectedRoute
                  exact
                  path={path}
                  key={routeKey}
                  render={() => <>{routeObject.component}</>}
                />
              );
            }
            // Route everything within the form to open magic link to bottom drawer
            if (location.pathname.includes('/form')) {
              return (
                <Route
                  exact={routeObject.exact}
                  path={path}
                  key={routeKey}
                  render={() => <ProductDetails navToForm={true} />}
                />
              );
            } else {
              return (
                <Route
                  exact={routeObject.exact}
                  path={path}
                  key={routeKey}
                  render={() => <>{routeObject.component}</>}
                />
              );
            }
          })}
          <Route>
            {location?.pathname === '/' ? (
              <Redirect to='/app/login' />
            ) : (
              <Redirect to='/app/404' />
            )}
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withLastLocation(Routes);
