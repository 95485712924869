import React, { useState, useEffect, useMemo, useRef, ReactNode } from 'react';
import { UrlParam } from 'types/Misc';
import { hex2rgba } from 'utils/hexToRgba';
import { useParams } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/storage';
import { getImageMeta } from 'utils/productUtils';
import { validateSocials } from 'utils/validateSocials';
import { useGlobal } from 'context/global/GlobalContext';
import { PossibleExperienceType } from 'types/ExperienceTypes';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useCartContext } from 'context/CartContext/CartContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import ProductGrid from 'components/drawers/ShopDrawer/ProductGrid';
import SocialIcons from 'components/atomic/SocialIcons';
import ProgressiveImage from 'react-progressive-image';

type PreviewPaneProps = {
  children: ReactNode;
};

const PreviewPane: React.FC<PreviewPaneProps> = ({ children }) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);
  const { slug, user, productDetails, desktopView, contentGateDisplay } =
    useGlobal();
  const { brandTheme, menuStyle } = useThemeContext();
  const { recommendations } = useCartContext();
  const { id } = useParams<UrlParam>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'previewPane',
  });

  const videoPlayer = useRef<HTMLVideoElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (videoPlayer && videoPlayer.current) videoPlayer.current.load();
  }, [productDetails]);

  const leadModule: any = useMemo(() => {
    return productDetails?.leadModule || {};
  }, [productDetails]);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'lg:rounded-2xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'lg:rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'lg:rounded-none';
      default:
        return 'lg:rounded-2xl';
    }
  };

  let { mediaToUse, mediaType } = getImageMeta(
    productDetails?.product?.image || '',
    renderOptimizedMedia
  );

  const leadInformation = useMemo(() => {
    if (productDetails?.unitTagDetails) {
      return (
        <div className='pt-2 flex flex-col'>
          <span tabIndex={0} className='text-xs leading-5'>
            {productDetails.unitTagDetails.registered
              ? t('registeredTo')
              : t('statusLabel')}
          </span>
          <span tabIndex={0} className='text-xs font-semibold leading-5'>
            {productDetails.unitTagDetails.registered
              ? user?.email === productDetails?.unitTagDetails?.email
                ? t('youLabel')
                : productDetails.unitTagDetails.user
              : t('unregisteredLabel')}
          </span>
        </div>
      );
    }

    switch (leadModule.type) {
      case 'SHOPPING_MODULE':
        if (leadModule.moduleInfo.showProductPriceHome) {
          const defaultVariantDetails =
            leadModule?.moduleInfo?.defaultVariantDetails;
          const price = defaultVariantDetails?.price || 0;
          const discountedPrice = defaultVariantDetails?.discountedPrice;
          return !discountedPrice ? (
            <p
              tabIndex={0}
              className='relative pt-2 w-full text-base font-semibold'
            >
              {`$${price}`}
            </p>
          ) : (
            <div role='presentation' className='pt-2 flex flex-col'>
              <span
                tabIndex={0}
                aria-label={`${t('ariaPreDiscountPrice')} $${price}`}
                className='text-[0.8rem] line-through text-muted'
              >
                {`$${price}`}
              </span>
              <span
                tabIndex={0}
                aria-label={`${t('ariaPrice')} ${discountedPrice}`}
                className='text-base font-semibold'
              >
                {`$${discountedPrice}`}
              </span>
            </div>
          );
        } else return null;

      case 'WARRANTY_MODULE':
        const { registeredTo, period, duration } = leadModule?.moduleInfo || {};
        const { enableWarrantyOnHomePage } = leadModule;
        const tagType = productDetails?.product?.tagType;

        if (registeredTo && tagType === 'Unit') {
          return (
            <div className='pt-2 flex flex-col'>
              <span tabIndex={0} className='leading-5 text-xs'>
                {t('registeredTo')}
              </span>
              <span tabIndex={0} className='font-semibold text-xs'>
                {registeredTo}
              </span>
            </div>
          );
        }

        if (!period && !duration) return null;
        if (!enableWarrantyOnHomePage) return null;

        return (
          <div className='pt-2 flex flex-col'>
            <span tabIndex={0} className='leading-5 text-xs'>
              {t('warrantyLabel')}
            </span>
            <span tabIndex={0} className='font-semibold text-xs'>
              {`${duration?.label !== 'Lifetime' ? period : ''} ${
                parseInt(period) === 1
                  ? duration?.label.substring(0, duration?.label.length - 1)
                  : duration?.label
              }`}
            </span>
          </div>
        );
      default:
        return null;
    }
  }, [user, leadModule, productDetails]);

  if (
    contentGateDisplay &&
    getLocalStorage().getItem('ageGateChecked') !== 'true'
  )
    return null;

  return desktopView &&
    location.pathname.includes(`/c/${slug}`) &&
    productDetails?.product.qrCodeDestination !==
      PossibleExperienceType.DIGITAL_HUB ? (
    <div
      className={`relative w-full max-w-[350px] h-[calc(100%_-_1.5rem)] max-h-[calc(100%_-_1.5rem)] flex flex-col m-3 overflow-auto ${getCornerStyle()}`}
      style={{ background: hex2rgba(brandTheme, 0.1) }}
    >
      <div className='relative grow'>{children}</div>
      {desktopView &&
      recommendations.length !== 0 &&
      location.pathname.includes(`/c/${slug}/shop`) ? (
        <div className='relative w-full h-[calc(100%-5.25rem)] p-4 pt-0'>
          <ProductGrid slug={id} />
        </div>
      ) : (
        <div className='relative w-full h-full flex flex-col justify-start p-4 pt-[84px]'>
          <Animated
            isVisible
            animationIn='fadeIn'
            animationOut='fadeIn'
            animationInDelay={300}
            className='relative flex w-full aspect-[3/4] my-auto rounded-xl overflow-hidden pointer-events-none'
          >
            {!!(mediaType.includes('mp4') || mediaType.includes('webm')) ? (
              <video
                loop
                muted
                autoPlay
                ref={videoPlayer}
                controls={false}
                playsInline={true}
                className='w-full object-cover'
                onError={(e) =>
                  (e.currentTarget.src = productDetails?.product?.image || '')
                }
              >
                <source src={mediaToUse} type={mediaType} />
              </video>
            ) : (
              <ProgressiveImage
                src={mediaToUse || ''}
                placeholder={productDetails?.brand?.name || ''}
                onError={() => {
                  setRenderOptimizedMedia(false);
                  console.log('Optimized Image Error');
                }}
              >
                {(src: string, loading: boolean) => {
                  return loading ? (
                    <div className='flex w-full bg-transparent' />
                  ) : (
                    <img
                      src={src}
                      alt={productDetails?.brand?.name}
                      className='relative w-full object-cover'
                    />
                  );
                }}
              </ProgressiveImage>
            )}
          </Animated>
          <div className='relative w-full mt-auto'>
            {leadInformation}
            {validateSocials(productDetails?.brand?.social) &&
              productDetails?.product.allowSocialIcons && (
                <SocialIcons
                  socials={productDetails?.brand?.social}
                  containerStyles='!p-0 !pt-4'
                  lightIcons
                />
              )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <>{children}</>
  );
};

export default PreviewPane;
