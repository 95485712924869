import React, { useCallback } from 'react';
import { User } from 'firebase/auth';
import { ProviderName } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { ReactComponent as GoogleLogo } from 'assets/logos/svg/google.svg';
import useFirebaseError from 'hooks/useFirebaseError';
import Button from 'components/atomic/Button';
import useFirebase from 'hooks/useFirebase';

interface SocialLoginProps {
  setLoading: (loading: boolean) => void;
  onSuccess: (user: User) => void;
  buttonText: string | undefined;
  validateEmailConsent?: () => boolean;
}

const SocialLogin: React.FC<SocialLoginProps> = ({
  setLoading,
  onSuccess,
  buttonText,
  validateEmailConsent,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'socialLogin' });
  const { getAuth, GoogleAuthProvider, signInWithPopup } = useFirebase();
  const getErrorMessage = useFirebaseError();
  const auth = getAuth();

  const handleSocialAuth = useCallback(
    async (providerName: ProviderName) => {
      if (!navigator.cookieEnabled) {
        showToast({
          message: t('cookiesDisabled'),
          type: 'error',
        });
        return;
      }

      setLoading(true);
      let provider = null;
      switch (providerName) {
        case ProviderName.Google:
          provider = new GoogleAuthProvider();
          break;
        default:
          provider = new GoogleAuthProvider();
      }

      try {
        const result = await signInWithPopup(auth, provider);
        let user = result?.user;
        onSuccess(user);
      } catch (e: any) {
        if (e.code === 'auth/popup-blocked') {
          const messageToShow = `${ProviderName.Google} ${t(
            'popupBlockedMessage'
          )}`;
          showToast({ message: messageToShow, type: 'error' });
          return;
        }
        showToast({
          message: getErrorMessage(e.code) ?? t('signinError'),
          type: 'error',
        });
        setLoading(false);
      }
    },
    [
      t,
      auth,
      onSuccess,
      setLoading,
      getErrorMessage,
      signInWithPopup,
      GoogleAuthProvider,
    ]
  );

  return (
    <Button
      transition
      variant='black'
      styles='!mt-1.5 !px-12'
      className='google-login-button'
      title={buttonText || t('continueGoogleButton')}
      onClick={() => {
        if (validateEmailConsent)
          validateEmailConsent() && handleSocialAuth(ProviderName.Google);
        else handleSocialAuth(ProviderName.Google);
      }}
      icon={
        <GoogleLogo fill='#000000' className='rounded-full absolute left-3' />
      }
    />
  );
};

export default SocialLogin;
