import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useAPI } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { AB899ModuleType } from 'types/ProductDetailsType';
import { FDA_REGULATIONS_LINK } from 'i18n/globalConstants';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import {
  TestResultsWithUniversalLotType,
  ProductTestedType,
} from 'types/TestResultsTypes';
import dateDifferenceCounter from 'utils/dateDifferenceCounter';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ToxicElementsTable from './ToxicElementsTable';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';

type AB899DrawerProps = {
  brandId: string;
  drawerTitle: string;
  drawerData: AB899ModuleType;
};

const AB899Drawer: React.FC<AB899DrawerProps> = ({
  brandId = '',
  drawerData,
  drawerTitle,
}) => {
  const [lotNumber, setLotnumber] = useState<string>('');
  const [upcNumber, setUpcNumber] = useState<string>('');
  const [expandedSection, setExpandedSection] = useState<number>(-1);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [testResults, setTestResults] = useState<
    TestResultsWithUniversalLotType[]
  >([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.ab899Drawer',
  });

  const {
    preLookup,
    postLookup,
    enableSearchByUpc,
    heavyMetalsResults,
    plasticizersResults,
    enableSearchByLotNumber,
    customizeDisclaimerText,
    customizeNoMessageResult,
    pesticidesAndGlyphosateResults,
  } = drawerData;

  const endpoint = `ab899/${brandId}?${
    lotNumber
      ? `lotNumber=${
          drawerData.enableSearchByLotNumber.ignoreSpecialCharacters
            ? lotNumber.replace(/[^a-zA-Z0-9]/g, '')
            : lotNumber
        }&`
      : ''
  }${
    upcNumber
      ? `upcNumber=${
          drawerData.enableSearchByUpc.ignoreSpecialCharacters
            ? upcNumber.replace(/[^a-zA-Z0-9]/g, '')
            : upcNumber
        }`
      : ''
  }`;
  const [fetchLotDetails] = useAPI({
    endpoint,
    method: 'GET',
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!lotNumber && !upcNumber) {
      showToast({
        message: t('errors.emptyLotOrUpcNumber'),
        type: 'error',
      });
    } else {
      setResultsLoading(true);
      try {
        let lotDetails = await fetchLotDetails();
        setTestResults(lotDetails?.testResults);
        // setUniversalTestResults(lotDetails?.universalTestResults);
        if (lotDetails?.testResults?.length === 1) setExpandedSection(0);
      } catch (error: any) {
        showToast({
          message: customizeNoMessageResult?.enabled
            ? customizeNoMessageResult.message
            : t('errors.noRecordFound'),
          type: 'error',
        });
      }
      setResultsLoading(false);
    }
  };

  const legalDisclaimer = customizeDisclaimerText?.enabled ? (
    <div
      tabIndex={0}
      dangerouslySetInnerHTML={{ __html: customizeDisclaimerText?.text || '' }}
      className='w-full text-xxs px-6 pt-1.5 pb-3 text-justify [&_a]:underline [&_a]:text-brij'
    />
  ) : (
    <p tabIndex={0} className='text-xxs px-6 pt-1.5 pb-3 text-justify'>
      {t('disclaimer')}
      <a
        href={FDA_REGULATIONS_LINK}
        target='_blank'
        rel='noreferrer'
        className='text-brij underline'
      >
        {t('disclaimerLink')}
      </a>
      .
    </p>
  );

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <div className='relative w-full flex flex-col items-center justify-center gap-3'>
        <HtmlWrapper html={testResults?.length > 0 ? postLookup : preLookup} />
        {!testResults || testResults.length === 0 ? (
          <section className='relative w-full flex flex-col mt-2.5 pb-3 gap-3 rounded-xl border border-solid border-lightgray overflow-hidden'>
            <div className='relative py-1.5 w-full text-center bg-minimalGray'>
              <h3
                tabIndex={0}
                className='font-bold uppercase text-lg text-black tracking-[0.5px]'
              >
                {t('testResultsLookup')}
              </h3>
            </div>
            {(!testResults || testResults?.length === 0) && (
              <div className='relative flex flex-col px-3 gap-3'>
                <p
                  tabIndex={0}
                  className='px-1.5 py-2 font-medium text-sm text-black tracking-[0.3px]'
                >
                  {enableSearchByUpc?.enabled &&
                  enableSearchByLotNumber?.enabled
                    ? t('enterUpcAndLotNumber')
                    : enableSearchByUpc?.enabled
                    ? t('enterUpcNumber')
                    : t('enterLotNumber')}
                </p>
                <form
                  onSubmit={handleSubmit}
                  className='relative flex flex-col gap-3'
                >
                  {enableSearchByUpc?.enabled && (
                    <Input
                      value={upcNumber}
                      name='upc-number-input'
                      autoComplete='upc-number-input'
                      required={enableSearchByUpc?.required || false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setUpcNumber(event.target.value)
                      }
                      placeholder={
                        upcNumber
                          ? t('upcNumberInputPlaceholder')
                          : t('upcNumberEmptyInputPlaceholder')
                      }
                    />
                  )}
                  {enableSearchByLotNumber?.enabled && (
                    <Input
                      value={lotNumber}
                      name='lot-number-input'
                      autoComplete='lot-number-input'
                      required={enableSearchByLotNumber?.required || false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setLotnumber(event.target.value)
                      }
                      placeholder={
                        lotNumber
                          ? t('lotNumberInputPlaceholder')
                          : `${t('lotNumberEmptyInputPlaceholder')} ${
                              enableSearchByUpc.enabled &&
                              !enableSearchByLotNumber.required
                                ? t('optionalPlaceholderText')
                                : ''
                            }`
                      }
                    />
                  )}
                  <Button
                    type='submit'
                    variant='dark'
                    title={t('submitLotNumberButton')}
                    disabled={!lotNumber && !upcNumber}
                    loading={resultsLoading}
                  />
                </form>
              </div>
            )}
            {legalDisclaimer}
          </section>
        ) : (
          <div className='relative w-full flex flex-col items-center justify-start gap-3'>
            {testResults.length > 0 &&
              testResults?.map(
                (result: TestResultsWithUniversalLotType, index: number) =>
                  result?.expirationDate &&
                  drawerData?.heavyMetalsResults.hidePostExpirationResults &&
                  drawerData.pesticidesAndGlyphosateResults
                    .hidePostExpirationResults &&
                  drawerData.plasticizersResults.hidePostExpirationResults &&
                  dateDifferenceCounter(result?.expirationDate) > 31 ? null : (
                    <section
                      key={result._id}
                      className='relative w-full flex flex-col items-center justify-star rounded-xl border border-solid border-lightgray overflow-hidden'
                    >
                      <button
                        type='button'
                        aria-expanded={expandedSection === index}
                        aria-controls={`expandable-content-${index}`}
                        aria-label={`${t('ariaToggleDetails')} ${result.lotNo}`}
                        className='relative w-full flex flex-row items-center justify-between px-4 py-2 duration-200 bg-minimalGray focus-visible:bg-gray-200'
                        onClick={() =>
                          expandedSection === index
                            ? setExpandedSection(-1)
                            : setExpandedSection(index)
                        }
                      >
                        <div className='w-full flex flex-col items-start'>
                          <p
                            tabIndex={0}
                            className='font-medium text-xs text-muted text-left text-wrap text-ellipsis whitespace-nowrap overflow-hidden'
                          >
                            {result.productsTested
                              ?.map(
                                (product: ProductTestedType) =>
                                  `${product.name}${
                                    product.upc ? ` (${product.upc})` : ''
                                  }`
                              )
                              .join(', ')}
                          </p>
                          <h3 tabIndex={0} className='font-bold text-left'>
                            {t('lotNumberHeading')}: {result.lotNo}
                          </h3>
                        </div>
                        <ExpandIcon
                          className={`min-w-[18px] min-h-[18px] transform duration-150 ${
                            index === expandedSection
                              ? 'rotate-180'
                              : 'rotate-0'
                          }`}
                          fill='#000000'
                          height={18}
                          width={18}
                        />
                      </button>
                      {expandedSection === index && (
                        <div
                          aria-controls={`expandable-content-${index}`}
                          className='relative w-full flex flex-col items-center justify-start p-3 gap-3'
                        >
                          {result?.heavyMetals &&
                          heavyMetalsResults?.enabled &&
                          result?.heavyMetals?.length > 0 ? (
                            <ToxicElementsTable
                              startExpanded
                              key='heavy-metals-table'
                              title={t('heavyMetalsHeading')}
                              toxicElements={result.heavyMetals}
                              toxicElementsResults={heavyMetalsResults}
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                              testResultsFile={
                                result?.testResultsFile &&
                                drawerData?.heavyMetalsResults
                                  .includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink: result?.testResultsFile || '',
                                      linkText:
                                        result?.testResultsFile &&
                                        drawerData?.heavyMetalsResults
                                          .includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? heavyMetalsResults
                                              .includeLinkToTestResultsDocument
                                              .linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : result?.universalTestResults &&
                            heavyMetalsResults?.enabled &&
                            result?.universalTestResults?.heavyMetals &&
                            result?.universalTestResults?.heavyMetals?.length >
                              0 ? (
                            <ToxicElementsTable
                              startExpanded
                              key='heavy-metals-table'
                              title={t('heavyMetalsHeading')}
                              toxicElements={
                                result?.universalTestResults?.heavyMetals
                              }
                              toxicElementsResults={heavyMetalsResults}
                              expirationDate={
                                result?.universalTestResults?.expirationDate ||
                                null
                              }
                              testingDate={
                                result?.universalTestResults?.testingDate ||
                                null
                              }
                              testResultsFile={
                                result?.universalTestResults?.testResultsFile &&
                                drawerData?.heavyMetalsResults
                                  ?.includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink:
                                        result?.universalTestResults
                                          ?.testResultsFile || '',
                                      linkText:
                                        result?.universalTestResults
                                          ?.testResultsFile &&
                                        drawerData?.heavyMetalsResults
                                          ?.includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? heavyMetalsResults
                                              ?.includeLinkToTestResultsDocument
                                              ?.linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : null}
                          {result?.pesticidesAndGlyphosateElements &&
                          pesticidesAndGlyphosateResults?.enabled &&
                          result?.pesticidesAndGlyphosateElements?.length >
                            0 ? (
                            <ToxicElementsTable
                              key='pesticides-and-glyphosate-table'
                              title={t('pesticidesAndGlyphosateHeading')}
                              toxicElements={
                                result.pesticidesAndGlyphosateElements
                              }
                              toxicElementsResults={
                                drawerData.pesticidesAndGlyphosateResults
                              }
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                              testResultsFile={
                                result?.testResultsFile &&
                                drawerData?.pesticidesAndGlyphosateResults
                                  .includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink: result?.testResultsFile || '',
                                      linkText:
                                        result?.testResultsFile &&
                                        drawerData
                                          ?.pesticidesAndGlyphosateResults
                                          .includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? pesticidesAndGlyphosateResults
                                              .includeLinkToTestResultsDocument
                                              .linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : result?.universalTestResults &&
                            pesticidesAndGlyphosateResults?.enabled &&
                            result?.universalTestResults
                              ?.pesticidesAndGlyphosateElements &&
                            result?.universalTestResults
                              ?.pesticidesAndGlyphosateElements?.length > 0 ? (
                            <ToxicElementsTable
                              key='pesticides-and-glyphosate-table'
                              title={t('pesticidesAndGlyphosateHeading')}
                              toxicElements={
                                result?.universalTestResults
                                  ?.pesticidesAndGlyphosateElements
                              }
                              toxicElementsResults={
                                drawerData?.pesticidesAndGlyphosateResults
                              }
                              expirationDate={
                                result?.universalTestResults?.expirationDate ||
                                null
                              }
                              testingDate={
                                result?.universalTestResults?.testingDate ||
                                null
                              }
                              testResultsFile={
                                result?.universalTestResults?.testResultsFile &&
                                drawerData?.pesticidesAndGlyphosateResults
                                  ?.includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink:
                                        result?.universalTestResults
                                          ?.testResultsFile || '',
                                      linkText:
                                        result?.universalTestResults
                                          ?.testResultsFile &&
                                        drawerData
                                          ?.pesticidesAndGlyphosateResults
                                          ?.includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? pesticidesAndGlyphosateResults
                                              ?.includeLinkToTestResultsDocument
                                              ?.linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : null}
                          {result.plasticizersElements &&
                          plasticizersResults?.enabled &&
                          result.plasticizersElements?.length > 0 ? (
                            <ToxicElementsTable
                              key='plasticizers-table'
                              title={t('plasticizersHeading')}
                              toxicElements={result.plasticizersElements}
                              toxicElementsResults={
                                drawerData.plasticizersResults
                              }
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                              testResultsFile={
                                result?.testResultsFile &&
                                drawerData?.plasticizersResults
                                  .includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink: result?.testResultsFile || '',
                                      linkText:
                                        result?.testResultsFile &&
                                        drawerData?.plasticizersResults
                                          .includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? plasticizersResults
                                              .includeLinkToTestResultsDocument
                                              .linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : result?.universalTestResults &&
                            plasticizersResults?.enabled &&
                            result?.universalTestResults
                              ?.plasticizersElements &&
                            result?.universalTestResults?.plasticizersElements
                              ?.length > 0 ? (
                            <ToxicElementsTable
                              key='plasticizers-table'
                              title={t('plasticizersHeading')}
                              toxicElements={
                                result?.universalTestResults
                                  ?.plasticizersElements
                              }
                              toxicElementsResults={
                                drawerData?.plasticizersResults
                              }
                              expirationDate={
                                result?.universalTestResults?.expirationDate ||
                                null
                              }
                              testingDate={
                                result?.universalTestResults?.testingDate ||
                                null
                              }
                              testResultsFile={
                                result?.universalTestResults?.testResultsFile &&
                                drawerData?.plasticizersResults
                                  ?.includeLinkToTestResultsDocument?.enabled
                                  ? {
                                      fileLink:
                                        result?.universalTestResults
                                          ?.testResultsFile || '',
                                      linkText:
                                        result?.universalTestResults
                                          ?.testResultsFile &&
                                        drawerData?.plasticizersResults
                                          ?.includeLinkToTestResultsDocument
                                          ?.enabled
                                          ? plasticizersResults
                                              ?.includeLinkToTestResultsDocument
                                              ?.linkText
                                          : t('viewFullReportButton'),
                                    }
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </section>
                  )
              )}
            {testResults?.length > 0 && (
              <div className='relative w-full'>
                <Button
                  type='button'
                  variant='dark'
                  title={t('startOverButton')}
                  onClick={() => {
                    setUpcNumber('');
                    setLotnumber('');
                    setTestResults([]);
                    setExpandedSection(-1);
                  }}
                />
              </div>
            )}
            {legalDisclaimer}
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default AB899Drawer;
