import { ChangeEvent } from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

type Props = {
  options: string[];
  name: string;
  defaultValue?: string;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
};

const RadioButtons = (props: Props) => {
  const { options, defaultValue, onChange, name } = props;
  const { brandTheme } = useThemeContext();

  return (
    <RadioGroup
      name={name}
      value={defaultValue ?? null}
      aria-labelledby='radio-buttons-group'
      onChange={(event) => onChange(event.target.value, event)}
      className='[&_.MuiTypography-body1]:text-sm [&_.MuiTypography-body1]:leading-5 [&_.MuiTypography-body1]:font-semibold [&_.MuiFormControlLabel-root]:mx-0 [&_.MuiFormControlLabel-root]:my-1.5'
    >
      {options.map((value) => {
        return (
          <FormControlLabel
            key={value}
            value={value}
            label={value}
            className='w-full flex items-start [&_.MuiRadio-root]:w-4'
            control={
              (
                <Radio
                  size='small'
                  style={{
                    height: '24px',
                    marginRight: '12px',
                    color: value === defaultValue ? brandTheme : '#414149',
                  }}
                />
              ) as any
            }
          />
        );
      })}
    </RadioGroup>
  );
};

export default RadioButtons;
