import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { getImageMeta } from 'utils/productUtils';
import ProgressiveImage from 'react-progressive-image';

type ProductButtonProps = {
  title: string;
  textColor: string;
  mediaLink: string;
  background: string;
  buttonStyle?: string;
  hideImage: boolean;
  onClick: () => void;
};

const ProductButton: React.FC<ProductButtonProps> = ({
  title,
  mediaLink,
  background,
  textColor,
  hideImage,
  buttonStyle,
  onClick,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);

  const getCornerRadius = () => {
    if (buttonStyle === 'largeCorner') return 'rounded-[50px]';
    else if (buttonStyle === 'smallCorner') return 'rounded-[10px]';
    else if (buttonStyle === 'noCorner') return 'rounded-none';
    else return 'rounded-[50px]';
  };

  const mediaClass = `relative left-0 w-[54px] h-[54px] self-center justify-self-start object-cover ${getCornerRadius()}`;

  let { mediaToUse, mediaType } = getImageMeta(mediaLink, renderOptimizedMedia);

  return (
    <button
      type='button'
      onClick={onClick}
      style={{ background: background, color: textColor }}
      className={`
        relative w-full h-productButton flex items-center justify-between flex-shrink-0 px-1.5 shadow-productButton focus-visible:ring-2 focus-visible:ring-black/60 
        ${buttonStyle ? getCornerRadius() : 'rounded-[50px]'}
      `}
    >
      {!hideImage && (
        <>
          {mediaType.includes('mp4') || mediaType.includes('webm') ? (
            <video
              loop
              muted
              autoPlay
              width='100%'
              height='100%'
              controls={false}
              playsInline={true}
              aria-label={title}
              className={mediaClass}
              onError={(e) => {
                e.currentTarget.src = mediaLink; // replace with your fallback video URL
              }}
            >
              <source type={mediaType} src={mediaToUse} />
            </video>
          ) : (
            <ProgressiveImage
              onError={() => {
                setRenderOptimizedMedia(false);
                console.error('Optimized Image Error');
              }}
              src={mediaToUse}
              placeholder={`${title.toLowerCase()}`}
            >
              {(src: string, loading: boolean) => {
                return loading ? (
                  <div className='flex min-h-full max-h-full w-full bg-transparent' />
                ) : (
                  <Animated
                    isVisible
                    animationIn='fadeIn'
                    animationOut='fadeIn'
                    className={`${mediaClass} overflow-hidden`}
                  >
                    <img
                      src={src}
                      aria-label={title}
                      alt={`${title.toLowerCase()}`}
                      className='relative w-full h-full m-auto object-cover'
                    />
                  </Animated>
                );
              }}
            </ProgressiveImage>
          )}
        </>
      )}
      <p
        className={`
          w-full px-2 font-semibold text-xs sm:text-base !leading-[120%] text-ellipsis overflow-hidden 
          ${
            !hideImage
              ? 'max-w-[calc(100%-108px)] -translate-x-[54px]'
              : 'max-w-full translate-x-0'
          }
        `}
      >
        {title}
      </p>
    </button>
  );
};

export default ProductButton;
