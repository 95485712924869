import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Chevron } from 'assets/icons/svg/arrow-down.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { Scrollbar } from 'react-scrollbars-custom';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import LoadingIndicator from '../LoadingIndicator';

type OptionType = {
  value: string;
  label: string;
};

type SearchableDropDownProps = {
  type: 'text' | 'number';
  selected: OptionType | undefined;
  name?: string;
  styles?: string;
  required?: boolean;
  placeholder?: string;
  options: OptionType[] | undefined;
  onChange?: (option: OptionType) => void;
  fetchRecords?: (query: string) => Promise<any>;
  scrollIntoView?: {
    enabled: boolean;
    parentHeight: number;
    parentContainerId: string;
  };
  allowedCustomValue?: boolean;
};

const SearchableDropDown: React.FC<SearchableDropDownProps> = ({
  type,
  name,
  styles,
  options,
  selected,
  required,
  onChange,
  placeholder,
  scrollIntoView,
  fetchRecords,
  allowedCustomValue,
}) => {
  const [borderRadius, setBorderRadius] = useState<string>('');
  const [showDropdown, toggleDropdown] = useState<boolean>(false);
  const [scrollbarOpacity, setScrollbarOpacity] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isServerSearch, setIsServerSearch] = useState<boolean>(false);
  const [serverOptions, setServerOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceRef = useRef<number | null>(null);
  const windowSize = useWindowSize();
  const { inputStyle } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'searchableDropDown',
  });

  const handleValueSelection = (value: string) => {
    setSearchTerm(value);
    toggleDropdown(false);
    onChange &&
      onChange({
        label: value,
        value: value,
      });
  };

  const filteredOptions = options?.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (selected?.value) {
      setSearchTerm(selected.label);
    }
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        toggleDropdown(false);

        // Handle value selection on click outside
        if (searchTerm && allowedCustomValue) {
          handleValueSelection(searchTerm);
        } else if (
          !allowedCustomValue &&
          !filteredOptions?.find((opt) => opt.label === searchTerm)
        ) {
          // Clear selection if custom values aren't allowed and no match found
          setSearchTerm('');
          onChange && onChange({ value: '', label: '' });
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [searchTerm, allowedCustomValue, filteredOptions, onChange]);

  useEffect(() => {
    if (showDropdown) {
      setTimeout(() => setScrollbarOpacity(100), 150);
      if (
        scrollIntoView?.enabled &&
        scrollIntoView?.parentContainerId &&
        containerRef?.current
      ) {
        const bottomSpace =
          scrollIntoView.parentHeight -
          containerRef.current?.getBoundingClientRect().bottom;
        if (bottomSpace < 0) {
          document
            .getElementById(scrollIntoView.parentContainerId)
            ?.scrollBy({ top: bottomSpace * -1 + 24, behavior: 'smooth' });
        }
      }
    } else {
      setTimeout(() => setScrollbarOpacity(0), 0);
    }
  }, [showDropdown, scrollIntoView, windowSize, containerRef]);

  useEffect(() => {
    if (inputStyle === CornerStyle.SQUARE_CORNERS) setBorderRadius('0px');
    else if (inputStyle === CornerStyle.ROUNDED_CORNERS)
      setBorderRadius('10px');
    else setBorderRadius('26px');
  }, [inputStyle]);

  const getDropdownHeight = () => {
    const optionsToDisplay = isServerSearch ? serverOptions : filteredOptions;

    if (loading) return 96;

    if (optionsToDisplay && optionsToDisplay.length <= 1) return 56;
    else if (optionsToDisplay && optionsToDisplay.length === 2) return 96;
    else return 136;
  };

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px]';
    }
  };

  const fetchServerResults = async (query: string) => {
    if (fetchRecords) {
      const response = await fetchRecords(query);
      setServerOptions(
        response.map((item: any) => ({
          value: item.text,
          label: item.text,
        }))
      );
      setLoading(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    toggleDropdown(true);

    if (value === '') {
      onChange && onChange({ value: '', label: '' });
      return;
    }

    if (value !== selected?.label) {
      onChange && onChange({ value: '', label: '' });
    }

    const wordCount = value.trim().length;

    if (wordCount > 2) {
      setLoading(true);

      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      setIsServerSearch(true);

      debounceRef.current = window.setTimeout(() => {
        fetchServerResults(value);
      }, 1000); // Adjust debounce delay as needed
    } else {
      setIsServerSearch(false);
    }
  };

  const optionsToRender = isServerSearch ? serverOptions : filteredOptions;

  return (
    <div
      ref={containerRef}
      className={`
        relative w-full h-input flex items-center mt-0.5 border-2 border-solid border-input transition-all ease-linear duration-150 cursor-pointer 
        [&_input]:z-20 [&_input]:w-full [&_input]:border-0 [&_input]:bg-transparent 
        [&_input]:active:bg-transparent [&_input]:active:outline-none 
        [&_input]:hover:bg-transparent [&_input]:hover:outline-none 
        ${selected?.label ? 'bg-transparent' : 'bg-white'} 
        ${getCornerStyle()} ${styles}
      `}
      style={{
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: showDropdown ? '0' : borderRadius,
        borderBottomRightRadius: showDropdown ? '0' : borderRadius,
      }}
    >
      <span
        className={`z-40 absolute mx-6 text-muted transition-all duration-150 pointer-events-none ${
          selected?.label || searchTerm
            ? 'text-xxxs px-0.5 bg-white -translate-x-0.5 -translate-y-[25px] opacity-100'
            : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
        }`}
      >
        {placeholder}
      </span>
      <div
        className='w-full relative'
        onClick={() => toggleDropdown(!showDropdown)}
      >
        <input
          ref={inputRef}
          name={name}
          type={type}
          width='100%'
          maxLength={50}
          value={searchTerm}
          required={required}
          onChange={handleSearchChange}
          onClick={() => !showDropdown && toggleDropdown(true)}
          className='!w-[calc(100%_-_2.5rem)] px-6 text-ellipsis overflow-hidden cursor-pointer'
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (type === 'number' && (event.key === 'e' || event.key === 'E')) {
              event.preventDefault();
            }
          }}
        />
        <button
          tabIndex={0}
          type='button'
          aria-label={t('ariaToggleDropdown')}
          className='absolute -top-1 right-3 w-8 h-8 flex items-center justify-center focus-visible:outline focus-visible:outline-black/60'
          onClick={() => toggleDropdown(!showDropdown)}
        >
          <Chevron
            className={`cursor-pointer duration-150 ${
              showDropdown ? 'rotate-180' : 'rotate-0'
            }`}
            width={24}
            height={24}
          />
        </button>
        <ul
          aria-hidden={!showDropdown}
          className={`absolute z-50 top-[38px] duration-150 shadow-dropdown bg-white ${
            showDropdown
              ? 'w-full left-0 overflow-auto py-2'
              : 'w-[calc(100%-3.25rem)] left-[1.625rem] overflow-hidden'
          }`}
          style={{
            height: showDropdown ? `${getDropdownHeight()}px` : '0px',
            ...{
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            },
          }}
        >
          <Scrollbar
            style={{ background: '#FFFFFF', opacity: scrollbarOpacity }}
          >
            {loading ? (
              <LoadingIndicator />
            ) : (
              optionsToRender?.map((option: OptionType) => (
                <li
                  tabIndex={showDropdown ? 0 : -1}
                  key={`dropdown-item-${option.value}`}
                  className='w-full h-10 flex items-center text-left px-6 leading-6 bg-white cursor-pointer hover:bg-lightgray focus-visible:bg-lightgray'
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setSearchTerm(option.label);
                      toggleDropdown(false);
                      onChange && onChange(option);
                    }
                  }}
                  onClick={() => {
                    setSearchTerm(option.label);
                    toggleDropdown(false);
                    onChange && onChange(option);
                  }}
                >
                  <label className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                    {option.label}
                  </label>
                </li>
              ))
            )}
            {!optionsToRender?.length && !loading && !allowedCustomValue && (
              <li
                key='no-result'
                className='w-full h-10 min-h-10 flex items-center px-6 cursor-pointer hover:bg-lightgray focus-visible:bg-lightgray'
              >
                <p className='w-full text-center font-semibold text-sm text-muted opacity-60'>
                  {t('noOptions')}
                </p>
              </li>
            )}
            {!optionsToRender?.length && !loading && allowedCustomValue && (
              <li
                tabIndex={showDropdown ? 0 : -1}
                key={`dropdown-item-${searchTerm}`}
                className='w-full h-10 flex items-center text-left px-6 bg-white cursor-pointer hover:bg-lightgray focus-visible:bg-lightgray'
                onClick={() => {
                  setSearchTerm(searchTerm);
                  toggleDropdown(false);
                  onChange &&
                    onChange({
                      label: searchTerm,
                      value: searchTerm,
                    });
                }}
              >
                {searchTerm}
              </li>
            )}
          </Scrollbar>
        </ul>
      </div>
    </div>
  );
};

export default SearchableDropDown;
